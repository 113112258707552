import * as colors from './colors';
import {
    FontFamily,
    FontWeight,
    serializeFontFamily,
    serializeFontWeight,
    serializeTextStyle,
    textStyles,
    TextVariants,
} from './text-styles';
import { sizes } from './sizes';

export type ThemeType = typeof theme;

declare module '@emotion/react' {
    export interface Theme extends ThemeType {}
}

export type variant = 'top' | 'campaign' | 'default' | undefined;

export const theme = {
    colors,
    fonts: {
        regular: serializeFontFamily('regular'),
        mono: serializeFontFamily('regular'),
        body: serializeFontFamily('regular'),
        heading: serializeFontFamily('alternative'),
    },
    fontWeights: {
        light: serializeFontWeight('Light'),
        regular: serializeFontWeight('Regular'),
        medium: serializeFontWeight('Medium'),
        bold: serializeFontWeight('Bold'),
        black: serializeFontWeight('Black'),
    },
    lineHeights: {
        single: '1',
        compact: '1.15',
        base: '1.5',
        headline: '1.1',
        double: '2',
        quarter: '1.75',
    },
    minHeights: {
        mobileHero: '256px',
    },
    fontSizes: {
        // these should probably be deleted
        exs: '10px',
        '2xs': '12px',
        xs: '14px',
        sm: '15px',
        md: '18px',
        lg: '20px',
        xl: '25px',
        '2xl': '30px',
        '3xl': '35px',
        '4xl': '40px',
        '5xl': '45px',
        '6xl': '50px',
        '7xl': '55px',
    },
    space: {
        '1': '4px',
        '2': '8px',
        '3': '16px',
        '4': '24px',
        '5': '32px',
        '6': '40px',
        '7': '80px',
        '8': '96px',
        '9': '130px',
        gutter: 'min(6vw, 115px)',
    },
    sizes: { ...sizes },
    metrics: {
        nano: '4px',
        micro: '8px',
        xsmall: '16px',
        small: '32px',
        medium: '48px',
        large: '56px',
        xlarge: '72px',
    },
    zIndices: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        max: 999,
    },
    easings: {
        smoothEase: 'cubic-bezier(0.35, 0, 0.15, 1)',
    },
    mixins: {
        useTextStyle: (style: TextVariants) => serializeTextStyle(textStyles[style]),
        useFontFamily: (fontFamily: FontFamily) => serializeFontFamily(fontFamily),
        useFontWeight: (fontWeight: FontWeight) => serializeFontWeight(fontWeight),
    },
    shadows: {
        button: '0 2px 20px 0 rgba(0,0,0,0.2)',
        productCard: '0 2px 20px 2px rgba(0,0,0,0.2)',
        floating: '0 2px 15px 0 rgba(0,0,0,0.2)',
        accessibility: 'rgba(0, 95, 204, 0.16) 0px 1px 4px, rgb(0, 95, 204) 0px 0px 0px 3px',
        accessibilityInset:
            'inset rgba(0, 95, 204, 0.16) 0px 1px 4px, inset rgb(0, 95, 204) 0px 0px 0px 3px',

        // Note: While `:focus-visible` is *intended* to appear only on keyboard focus, some browsers also
        // trigger it on mouse click (especially for text input fields). This is a no go in some places like
        // searchInput where the blue color interrupts the styling of the website too much.
        // We will use this accessibility styling instead for such cases.
        accessibilityTextField:
            'rgba(35, 31, 32, 0.16) 0px 1px 4px, rgb(35, 31, 32) 0px 0px 0px 1px',
    },
};
